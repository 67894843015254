import React from 'react';
import Countdown from 'react-countdown';
import { Link } from 'gatsby';
import EventSlider from 'react-slick';
import Layout from '../../../components/Layout';
import Copyright from '../../../components/Copyright';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import ThPartnerLogo from '../../../components/Header/brand-logo.svg';
import s from './Events.module.scss';
import ThLogo from './th-logo-white.svg';
import AwsLogo from './aws-logo.svg';
import AwsDarkLogo from './aws-dark-logo.svg';
import AwsPartnerLogo from './aws-partner-logo.svg';
import DateIcon from './date-icon.svg';
import LocationIcon from './map-icon.svg';
import StudiosImage from './moxy-studios-night.jpg';
import LoungeImage from './moxy-lobby-lounge.jpg';
import DronImage from './la-dron.jpg';

const Completionist = () => <div className={s.countdown}>You are good to go!</div>;

const addLeadingZero = (value) => {
  return value < 10 ? `0${value}` : value;
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  }
  return (
    <div className={s.countdown}>
      <div className={s.count}>
        <h2>{addLeadingZero(days)}</h2>
        <h3>days</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(hours)}</h2>
        <h3>hours</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(minutes)}</h2>
        <h3>mins</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(seconds)}</h2>
        <h3>secs</h3>
      </div>
    </div>
  );
};

const seoParams = {
  ogImagePath: '/og-images/aws-happy-hours.jpg',
  ogTitle: 'Techholding Happy Hour | Tech Holding',
  ogDesc:
    'Discover the story behind TechHolding, our mission, and our commitment to innovation. Learn about our team of experts driving technological advancement and digital transformation.',
  ogPath: '/events/aws-lasummit-happy-hour',
};

const updatedSeo = getSeoConfig(seoParams);

const getUtcDate = (year, month, date, hours = 0, minutes = 0, seconds = 0) => {
  const utcDate = new Date(Date.UTC(year, month - 1, date, hours, minutes, seconds));
  return utcDate;
};

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
};

const Event = () => (
  <Layout
    currentPage="/events/aws-lasummit-happy-hour"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
  >
    <div className={s.eventPage}>
      <div className={s.sliderWrapper} data-aos="fade-up" data-aos-delay="200">
        <EventSlider {...settings} className={s.fullSlider}>
          <div className={s.slideImage}>
            <img src={StudiosImage} alt="" />
          </div>
          <div className={s.slideImage}>
            <img src={LoungeImage} alt="" />
          </div>
          <div className={s.slideImage}>
            <img src={DronImage} alt="" />
          </div>
        </EventSlider>
        <div className={s.hero}>
          <div className={s.container}>
            <div className={s.heroLogo}>
              <ThLogo />
              <span className={s.logoLine} />
              <AwsLogo />
            </div>
            <div className={s.eventSchedule}>
              <div className={s.flexSchedule}>
                <h1>Join Tech Holding and AWS for HAPPY HOUR</h1>
                <Countdown date={getUtcDate(2024, 5, 23, 0, 0, 0)} renderer={renderer} />
              </div>
              <div className={s.flex}>
                <div className={s.iconText}>
                  <DateIcon />
                  <span>May 22, 2024</span>
                </div>
                <div className={s.iconText}>
                  <LocationIcon />
                  <span>
                    <strong>Moxy Studio DTLA</strong>
                    <strong className={s.iconTextSmall}>
                      1260 South Figueroa Street, Los Angeles, CA, 90015
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.eventMain} data-aos="fade-up" data-aos-delay="400">
        <div className={s.container}>
          <div className={s.eventContent}>
            <div className={s.eventMainContent}>
              <p>
                We’re thrilled to extend a warm invitation to you for a Happy Hour following the AWS
                Summit. Unwind and join Tech Holding & AWS on May 22, 2024, from 5:00 PM to 8:00 PM
                at Moxy Studio DTLA for an exclusive evening filled with insights as we share
                lessons learned and explore emergent topics, networking, and fun in the world of
                AWS.
              </p>
              <h4>What to Expect:</h4>
              <ul>
                <li>
                  <b>Networking: </b>
                  Connect with fellow professionals in the industry. Share insights, experiences,
                  and lessons learned from the Summit.
                </li>
                <li>
                  <b>Refreshments: </b>
                  Enjoy light snacks and drinks on us. It’s the perfect opportunity to unwind after
                  an information-packed day.
                </li>
                <li>
                  <b>Relaxation: </b>
                  Take a break, soak in the atmosphere, and continue the conversation in a more
                  relaxed setting.
                </li>
              </ul>
              <p>
                This event features a private bar area and raffle games, filled with entertainment.
                This event is FREE, but space is limited. Secure your spot by RSVP. We look forward
                to raising a glass with you.
              </p>
            </div>
            <div className={s.awsPartner}>
              <div className={s.flex}>
                <div className={s.partnerLogo}>
                  <AwsPartnerLogo />
                </div>
                <ul className={s.partnerList}>
                  <li>Solution Provider</li>
                  <li>Amazon RDS Delivery</li>
                  <li>AWS Lambda Delivery</li>
                  <li>Amazon API Gateway Delivery</li>
                  <li>Amazon OpenSearch Service Delivery</li>
                </ul>
              </div>
            </div>

            <div className={s.sponsorsSection}>
              <h3>Sponsors</h3>
              <div className={s.flex}>
                <ThPartnerLogo />
                <AwsDarkLogo />
              </div>
            </div>
          </div>
          <div className={s.eventForm}>
            <div className={s.registerForm}>
              <h3>Register Now</h3>
              <p>This event has ended. Thanks for your participation!</p>
            </div>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<div id="mc_embed_shell" class="register-form disabled-form">
              <div id="mc_embed_signup">
                  <form
                      action="https://techholding.us18.list-manage.com/subscribe/post?u=12eacc3d78ee8cee150f4a09c&amp;id=6d2ac1e5cd&amp;f_id=00a016e7f0"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      class="validate"
                      target="_blank"
                  >
                      <div id="mc_embed_signup_scroll">
                          <h3>Register Now</h3>
                          <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                          <div class="mc-field-group">
                             <input type="text" name="FNAME" class="form-input required text" id="mce-FNAME" required="" placeholder="Enter First Name" value="" />
                             <label class="form-label" for="mce-FNAME">First Name <span class="asterisk">*</span></label>
                          </div>
                          <div class="mc-field-group">
                             <input type="text" name="LNAME" class="form-input required text" id="mce-LNAME" required="" placeholder="Enter Last Name" value="" />
                             <label class="form-label" for="mce-LNAME">Last Name <span class="asterisk">*</span></label>
                          </div>
                          <div class="mc-field-group">
                            <input type="email" name="EMAIL" class="form-input required email" id="mce-EMAIL" required="" placeholder="Enter Email Address" value="" />
                            <label class="form-label" for="mce-EMAIL">Email Address <span class="asterisk">*</span></label>
                          </div>
                          <div class="mc-field-group">
                            <input type="text" name="COMPNAME" class="form-input required text" id="mce-COMPNAME" required="" placeholder="Enter Company Name" value="" />
                            <label class="form-label" for="mce-COMPNAME">Company Name <span class="asterisk">*</span></label>
                          </div>
                          <div aria-hidden="true" style="position: absolute; left: -5000px;">
                              /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                              <input type="text" name="b_12eacc3d78ee8cee150f4a09c_6d2ac1e5cd" tabindex="-1" value="" />
                          </div>
                          <div class="optionalParent">
                              <div class="foot">
                                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Register" />
                              </div>
                          </div>
                          <div id="mce-responses" class="clear foot">
                              <div class="response" id="mce-error-response" style="display: none;"></div>
                              <div class="response" id="mce-success-response" style="display: none;"></div>
                          </div>
                      </div>
                  </form>
              </div>
          </div>          
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[6]='COMPNAME';ftypes[6]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>`,
              }}
            />
          </div>
        </div>
      </div>

      <div className={s.homeFooter}>
        <footer className={s.root}>
          <div className={s.footerTop}>
            <div className={s.container}>
              <div className={s.footerRow}>
                <div className={s.footerMenu}>
                  <h5>Company</h5>

                  <ul className={s.footerMenuList}>
                    <li className={s.footerMenuItem}>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/services">What We Do</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/work">Case Studies</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={s.footerLine} />
              <div className={s.copyrightContainer}>
                <Copyright />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </Layout>
);

export default Event;
